import Vue from 'vue';
import ct from 'countries-and-timezones';

Vue.filter('currency', function (value, baseCurrency, quoteCurrency = false, exchangeRate = false) {

    const timezone = ct.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const country = timezone.countries[0];

    const countryToRegionMap = {
        CH: 'de-CH',
        US: 'en-US',
        DE: 'de-DE',
        FR: 'fr-FR',
        GB: 'en-GB',
        BE: 'fr-BE',
    };

    const region = countryToRegionMap[country] || 'en-US';

    let price;
    const round = (value, precision = 100) => Math.floor(value * precision) / precision;

    // Ensure that currency codes are provided
    if (!baseCurrency) {
        console.warn("Base currency is required with currency style.");
        return value; // Return raw value or a fallback
    }

    if (!quoteCurrency || !exchangeRate || quoteCurrency === baseCurrency) {
        price = new Intl.NumberFormat(region, {
            style: 'currency',
            currency: baseCurrency,  // Ensure baseCurrency is defined
        }).format(value);
    } else {
        const amount = round(value * exchangeRate);
        price = new Intl.NumberFormat(region, {
            style: 'currency',
            currency: quoteCurrency,  // Ensure quoteCurrency is defined
        }).format(amount);
    }

    return price;
});


// New filter for raw numeric conversion without formatting
Vue.filter('currencyValue', function (value, baseCurrency, quoteCurrency = false, exchangeRate = false) {
    // Rounding function
    const round = (value, precision = 100) => Math.floor(value * precision) / precision;

    // Handle cases where exchange rate or quote currency are not provided
    if (!quoteCurrency || !exchangeRate || quoteCurrency === baseCurrency) {
        return round(value);
    } else {
        // Convert the value with the exchange rate and return the numeric value only
        return round(value * exchangeRate);
    }
});